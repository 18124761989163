import React, { Fragment } from "react";
import { Header, Footer } from "../components/opencoin-ui";
import { NavLink } from "react-router-dom";
import { RiDiscordLine, RiMediumLine, RiShoppingCart2Line, RiTelegramLine, RiTwitterLine } from "react-icons/ri";
export default function Home() {
    return (
        <>
            <Header />
            <section className="relative px-4 py-4 overflow-hidden bg-white md:px-12 lg:px-28 dark:bg-grayc-950 md:py-20 lg:py-20 font-body">
                <div className="space-y-5">
                    <h1 className="text-3xl font-bold text-center text-transparent md:text-5xl lg:text-5xl xl:text-5xl bg-gradient-to-r from-yellow-500 via-orange-500 to-red-500 bg-clip-text">
                        OpenCoin Staking Infrastructure
                    </h1>
                    <h2 className="text-3xl font-bold leading-tight text-center text-gray-900 md:leading-tight lg:leading-tight xl:leading-tight md:text-5xl lg:text-5xl xl:text-5xl dark:text-white">
                        Safe, Fast, And Secure <br /> dApps Web 3.0
                    </h2>

                    <p className="text-xl text-center text-gray-800 dark:text-gray-400">Liquid Staking with dApps Web 3.0</p>
                    <div className="flex justify-center gap-5">
                        <NavLink to="/staking" className="px-8 py-4 text-white bg-yellow-600 hover:bg-yellow-700 rounded-2xl">Launch App</NavLink>
                        <NavLink to="/referral" className="px-8 py-4 text-gray-900 bg-gray-300 dark:bg-gray-700 dark:text-gray-200 hover:bg-gray-400 rounded-2xl">Referral</NavLink>
                    </div>
                </div>
            </section>
            <section className="relative px-4 py-4 overflow-hidden bg-gray-100 md:px-12 lg:px-28 dark:bg-grayc-950 md:py-20 lg:py-20 font-body">
                <div className="p-12 mb-16 bg-white md:p-16 lg:p-16 xl:p-16 rounded-3xl dark:bg-gray-700">
                    <div className="flex items-center justify-between w-full">
                        <div className="mb-3 text-2xl font-semibold text-yellow-600">For Stakers</div>
                        <div>
                            <img src="/images/illustration/shape.png" className="absolute -mt-32 -ml-16" width="150" height="150" alt="shape" />
                        </div>
                    </div>
                    <h1 className="text-3xl font-bold leading-tight text-gray-900 md:text-5xl lg:text-5xl xl:text-5xl dark:text-white">
                        User-friendly, profitable, Web 3.0, and <br /> have referral mechanism
                    </h1>
                    <hr className="my-6" />
                    <div className="flex flex-col justify-between w-full md:flex-row lg:flex-row xl:flex-row">
                        <div className="text-xl font-semibold text-black dark:text-white"><span className="text-xl font-semibold text-yellow-700">1. </span>Buy Token</div>
                        <div className="text-xl font-semibold text-black dark:text-white"><span className="text-xl font-semibold text-yellow-700">2. </span>Stake Your Token</div>
                        <div className="text-xl font-semibold text-black dark:text-white"><span className="text-xl font-semibold text-yellow-700">3. </span>Share your referral</div>
                    </div>
                </div>
                <div className="p-12 mb-16 bg-white md:p-16 lg:p-16 xl:p-16 rounded-3xl dark:bg-gray-700">
                    <div className="flex items-center justify-between w-full">
                        <div className="mb-3 text-2xl font-semibold text-yellow-600">About Us</div>
                        <div>
                            <img src="/images/illustration/opencoin.png" className="absolute -mt-32 -ml-16" width="180" height="180" alt="shape" />
                        </div>
                    </div>
                    <h1 className="text-3xl font-bold leading-tight text-gray-900 md:text-5xl lg:text-5xl xl:text-5xl dark:text-white">
                        What is OpenCoin
                    </h1>
                    <h2 className="my-5 text-xl text-black dark:text-white">
                        Open coin is a Decentralized Finance project, operating under an Automated Mechanism and using Smart Contracts on the Binance smart chain to help speed up transaction processing, low transaction fees, safety and transparency.
                        Dapp operates based on web 3.0, making it easy for users to use and highly secure.
                        With a mining profit of X2 capital within 200 days, helping OpenCoin become the project worth investing in in the coming years.
                        Unlimited investment amount, profit and capital paid in 1 day 1%/day x200 days.
                        5% referral commission when Investors stake
                    </h2>
                    <hr className="my-6 text-black dark:text-gray-500" />
                    <div className="flex justify-end w-full">
                        <a href="/staking" className="flex items-center gap-2 px-8 py-4 text-white bg-yellow-600 hover:bg-yellow-700 rounded-2xl"><RiShoppingCart2Line /> Buy Coin</a>
                    </div>
                </div>

                <div className="p-12 mb-16 bg-white md:p-16 lg:p-16 xl:p-16 rounded-3xl dark:bg-gray-700">
                    <div className="mb-3 text-2xl font-semibold text-yellow-600">Token Information</div>
                    <h1 className="text-3xl font-bold leading-tight text-gray-900 md:text-5xl lg:text-5xl xl:text-5xl dark:text-white">
                        OpenCoin Token Information
                    </h1>
                    <div className="flex items-center justify-between w-full mt-12">
                        <div className="space-y-4">
                            <h1 className="text-xl font-semibold">Name: OpenCoin</h1>
                            <h1 className="text-xl font-semibold">Symbol: OPC</h1>
                            <h1 className="text-xl font-semibold">Decimals: 18</h1>
                        </div>
                        <img src="/images/illustration/opencoin-front.png" className="" width="180" height="180" alt="shape" />
                        <div className="space-y-4">
                            <h1 className="text-xl font-semibold">Supply: 1.000.000</h1>
                            <h1 className="text-xl font-semibold">Network: Binance Smart Chain</h1>
                        </div>
                    </div>
                    <hr className="my-6 text-black dark:text-gray-500" />
                    <div className="flex justify-end w-full">
                        <a href="/staking" className="flex items-center gap-2 px-8 py-4 text-white bg-yellow-600 hover:bg-yellow-700 rounded-2xl"><RiShoppingCart2Line /> Buy Coin</a>
                    </div>
                </div>

                <div className="p-12 mb-16 bg-white md:p-16 lg:p-16 xl:p-16 rounded-3xl dark:bg-gray-700">
                    <div className="flex items-center justify-between w-full">
                        <div className="mb-3 text-2xl font-semibold text-yellow-600">Token Allocation</div>
                        <div>
                            <img src="/images/illustration/allocation.png" className="absolute -mt-32 -ml-16" width="180" height="180" alt="shape" />
                        </div>
                    </div>
                    <h1 className="mb-12 text-3xl font-bold leading-tight text-gray-900 md:text-5xl lg:text-5xl xl:text-5xl dark:text-white">
                        Token Allocation
                    </h1>
                    <h2 className="my-5 text-xl text-black dark:text-white">
                        <div className="overflow-y-scroll">
                            <table className="table-auto w-full">
                                <tbody className="text-sm divide-y divide-gray-100">
                                    <tr>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-left">Private Sale 200.000 OPC</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-left">Selling Angels 100.000 OPC</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-left">Open Pool 400.000 OPC</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-left">Open Cloud 100.000 OPC</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-left">Open Team 100.000 OPC</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-left">Aidrop 1.000 OPC</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-left">Ecosystem 20.000 OPC</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-left">Mining 20.000 OPC</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-left">Marketing 9.000 OPC</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-left">CEX 40.000 OPC</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-left">Partnership 10.000 OPC</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </h2>
                    <hr className="my-6 text-black dark:text-gray-500" />
                    <div className="flex justify-end w-full">
                        <a href="/staking" className="flex items-center gap-2 px-8 py-4 text-white bg-yellow-600 hover:bg-yellow-700 rounded-2xl"><RiShoppingCart2Line /> Buy Coin</a>
                    </div>
                </div>
                <div className="p-12 mb-16 md:p-16 lg:p-16 xl:p-16 rounded-3xl">
                    <h2 className="mb-12 text-5xl font-bold leading-tight text-center text-gray-900 dark:text-white">
                        Join to our community
                    </h2>
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
                        <a href="https://" className="p-6 bg-white rounded-xl hover:bg-yellow-500 hover:text-black dark:bg-gray-700 dark:text-white">
                            <h1 className="text-lg font-bold">Twitter</h1>
                            <h2 className="my-3 text-gray-700 dark:text-gray-500">
                                General Announcements
                            </h2>
                            <div className="flex justify-end">
                                <RiTwitterLine className="text-3xl" />
                            </div>
                        </a>
                        <a href="https://" className="p-6 bg-white rounded-xl hover:bg-yellow-500 hover:text-black dark:bg-gray-700 dark:text-white">
                            <h1 className="text-lg font-bold">Telegram</h1>
                            <h2 className="my-3 text-gray-700 dark:text-gray-500">
                                General Announcements
                            </h2>
                            <div className="flex justify-end">
                                <RiTelegramLine className="text-3xl" />
                            </div>
                        </a>
                        <a href="https://" className="p-6 bg-white rounded-xl hover:bg-yellow-500 hover:text-black dark:bg-gray-700 dark:text-white">
                            <h1 className="text-lg font-bold">Discord</h1>
                            <h2 className="my-3 text-gray-700 dark:text-gray-500">
                                General Announcements
                            </h2>
                            <div className="flex justify-end">
                                <RiDiscordLine className="text-3xl" />
                            </div>
                        </a>
                        <a href="https://" className="p-6 bg-white rounded-xl hover:bg-yellow-500 hover:text-black dark:bg-gray-700 dark:text-white">
                            <h1 className="text-lg font-bold">Medium</h1>
                            <h2 className="my-3 text-gray-700 dark:text-gray-500">
                                General Announcements
                            </h2>
                            <div className="flex justify-end">
                                <RiMediumLine className="text-3xl" />
                            </div>
                        </a>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}